import jwtDecode from 'jwt-decode';

import { logEvent } from '../helpers/logger';
import { useAuthContext } from './useAuthContext';

export const useLogin = () => {
  const { dispatch } = useAuthContext();

  const handleDispatch = async (token) => {
    const decodedToken = jwtDecode(token);
    const userLocal = decodedToken.data;

    const user = {
      ...userLocal,
      expiry: new Date(decodedToken.exp * 1000),
    };
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);

    logEvent('Sign In Verified');

    dispatch({
      type: 'LOGIN',
      payload: userLocal,
    });
    return userLocal;
  };

  return { handleDispatch };
};
