import React from 'react';

export function ReportConflictProcessChart() {
  const elements = [
    'Complete Assessment',
    'Review Report',
    'Gain Awareness of your conflcit styles',
    'Set an action plan for change',
  ];
  return (
    <div className="report-conflict-chart-process-shapecontainer">
      {elements.map((element) => (
        <div className="report-conflict-chart-process-shape" key={element}>
          <h5 className="report-conflict-process-text mt-5">{element}</h5>
        </div>
      ))}
    </div>
  );
}
