/* eslint-disable no-restricted-globals */
import * as amplitude from '@amplitude/analytics-browser';
import { omit } from 'lodash-es';

const getDiagostics = () => ({
  windowWidth: window.outerWidth,
  windowHeight: window.outerHeight,
  screenWidth: screen.width,
  screenHeight: screen.height,
  screenOrientation: screen.orientation.type,
});

const getUser = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  return omit(user, ['dateOfBirth', 'gender']);
};

export const logEvent = (name, details) => {
  console.log(name, details);
  amplitude.track(
    name,
    {
      details,
      user: getUser(),
      diagnostics: getDiagostics(),
    },
  );
};

export const logError = (name, details) => {
  console.error(name, details);
  amplitude.track(name, {
    details,
    user: getUser(),
    diagnostics: getDiagostics(),
    type: 'Error',
  });
};
