import { BarChart as eBarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ITPMETRICS_BLUE, ITPMETRICS_YELLOW } from '../common/Colours';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  eBarChart,
  CanvasRenderer,
  LegendComponent,
  MarkLineComponent,
]);

export function ReportBarChart({ peerRating, selfRating }) {
  const [chartImage, setChartImage] = useState(null);
  const getOption = () => ({
    animation: false,
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: 'white',
      borderColor: 'black',
      textStyle: { color: 'dark' },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
      formatter: (params) => {
        if (!params[0].data) {
          return `${params[0].name}: Not available. There is insufficient feedback for this competency.`;
        }
        return `${params[0].name}: ${Number(params[0].value).toFixed(1)}`;
      },
    },
    xAxis: [{
      type: 'value',
      boundaryGap: '0%',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        formatter: (value) => ['Unsatisfactory', 'Bare Minimum', 'Moderate', 'Strong', 'Outstanding'][value - 1],
      },
      splitLine: { show: true },
      min: 1,
      max: 5,
    }],
    yAxis: {
      type: 'category',
      data: ['Self Rating', 'Peer Rating'],
      boundaryGap: '0%',
      axisLabel: {
        formatter: (value) => value.substring(0, value.length),
        show: true,
      },
      splitLine: {
        show: false,
        lineStyle: {},
      },
      axisTick: { show: true },
      axisLine: {
        lineStyle: {},
      },
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        barWidth: 50,
        data: [Number(selfRating), Number(peerRating)],
        markLine: {
          symbol: 'none',
          data: [{
            xAxis: 1,
            label: {
              show: false,
              formatter: () => '',
            },
            lineStyle: { color: 'grey', width: 2, type: 'solid' },
          },
          {
            xAxis: 2,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: ITPMETRICS_YELLOW, width: 2, type: 'dotted' },
          }, {
            xAxis: 3,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: 'grey', width: 2, type: 'solid' },
          },
          {
            xAxis: 4,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: ITPMETRICS_YELLOW, width: 2, type: 'dotted' },
          },
          {
            xAxis: 5,
            label: { show: false, formatter: () => '' },
            lineStyle: { color: 'grey', width: 2, type: 'solid' },
          }],
        },
        itemStyle: {
          color: (params) => {
            if (params.name === 'Self Rating') {
              return ITPMETRICS_YELLOW;
            }
            return ITPMETRICS_BLUE;
          },
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true,
        },
        label: {
          show: true,
          position: 'right',
        },
      },
    ],
    grid: {
      containLabel: true,
      right: 50,
      left: 20,
      bottom: 20,
      top: 20,
    },
  });
  return (
    <div className="m-3 p-3">
      <img alt="bar-chart" src={chartImage} style={{ width: '100%' }} className="print-only" />
      <ReactEChartsCore
        className="no-print"
        echarts={echarts}
        option={getOption()}
        style={{ height: '12em' }}
        ref={(e) => {
          if (e) {
            setChartImage(e.getEchartsInstance().getDataURL());
          }
        }}
      />
    </div>
  );
}

ReportBarChart.propTypes = {
  peerRating: PropTypes.string,
  selfRating: PropTypes.string,
};

ReportBarChart.defaultProps = {
  peerRating: null,
  selfRating: null,
};
