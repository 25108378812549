import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ReviewCard from './ReviewCard';

export default function ReviewDashboardCards({
  participants, reviewId, onChanged,
}) {
  return (
    <Row className="pb-4">
      {participants.map((participant, index) => (
        <Col
          className="px-1 col-same-height"
          // eslint-disable-next-line react/no-array-index-key
          key={`review-card-${index}`}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          <ReviewCard
            reviewId={reviewId}
            participant={participant}
            onChanged={onChanged}
          />
        </Col>
      ))}
    </Row>
  );
}

ReviewDashboardCards.propTypes = {
  participants: PropTypes.array,
  reviewId: PropTypes.string,
  onChanged: PropTypes.func,
};
