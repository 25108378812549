import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Section from '../../common/Section';

function ErrorDialog({ content }) {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} xxl={4}>
          <Container className="text-center">
            <Card>
              <Card.Body className="p-4 p-sm-5">
                {content}
                <Link className="btn btn-primary btn-sm mt-3" to="/" reloadDocument>
                  <FontAwesomeIcon icon={faHome} className="me-2" />
                  Home
                </Link>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </Section>
  );
}

ErrorDialog.propTypes = {
  content: PropTypes.node,
};

ErrorDialog.defaultProps = {
  content: null,
};

export default ErrorDialog;
