import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';

import ScorePill from '../../common/ScorePill';
import StatusPill from '../../common/StatusPill';
import RespondentCardDropDown from './RespondentCardDropDown';

function RespondentCard({
  surveyId, respondent, onChanged, surveyStatus,
}) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <Card className="m-2 p-2 h-100">
      <Card.Body className="p-2">
        <Row className="h-100">
          <Col>
            <ClampLines
              text={`${respondent.firstName} ${respondent.lastName}`}
              lines={1}
              buttons={false}
              className="respondent-card-title"
            />
            <Card.Text className="text-muted small">{respondent.email}</Card.Text>
            <Row>
              <Col>
                <StatusPill status={respondent.status} />
                {respondent.peerScore
                && (
                <span className="px-1">
                  <ScorePill size="md" score={respondent.peerScore} />
                </span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="position-absolute" style={{ left: '91%', top: '13%' }}>
          <RespondentCardDropDown
            onChanged={onChanged}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            surveyId={surveyId}
            respondentId={respondent.respondentId}
            completedAt={respondent.completedAt}
            status={respondent.status}
            surveyStatus={surveyStatus}
            email={respondent.email}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

RespondentCard.propTypes = {
  surveyId: PropTypes.string,
  respondent: PropTypes.object,
  onChanged: PropTypes.func,
  surveyStatus: PropTypes.string,
};

export default RespondentCard;
