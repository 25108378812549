import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import UploadParticipantListModal from '../modals/UploadParticipantListModal';

function UploadParticipantsButton({
  survey, template, onChanged, demo,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        title="Upload participant list"
        size={demo ? 'sm' : 'md'}
        variant="light"
        disabled={demo}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faUsers} />
      </Button>
      {showModal && (
      <UploadParticipantListModal
        surveyId={survey.surveyId}
        templateType={template.type}
        onCancel={() => setShowModal(false)}
        onChanged={async () => {
          await onChanged();
          setShowModal(false);
        }}
      />
      )}
    </>

  );
}

UploadParticipantsButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    templateId: PropTypes.string,
    createdBy: PropTypes.string,
    status: PropTypes.string,
    _id: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  template: PropTypes.shape({
    type: PropTypes.string,
  }),
  onChanged: PropTypes.func,
  demo: PropTypes.bool,
};

export default UploadParticipantsButton;
