import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

function PreviewSurveyButton({ survey }) {
  return (
    <Button
      title="Preview survey"
      size="md"
      variant="light"
      href={`/template/${survey.templateId}/0`}
      target="_blank"
    >
      <FontAwesomeIcon icon={faUpRightFromSquare} />
    </Button>
  );
}

PreviewSurveyButton.propTypes = {
  survey: PropTypes.shape({
    templateId: PropTypes.string,
  }),
};

export default PreviewSurveyButton;
