import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';

import { version } from '../../../config';
import Section from '../../common/Section';

function FooterStandard() {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const onKeyEnter = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        scrollToTop();
      }
    },
    [scrollToTop],
  );

  return (
    <>
      <Section bg="dark" className="pt-8 pb-4 light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-dark"
          onClick={scrollToTop}
          onKeyDown={onKeyEnter}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
      </Section>

      <section className=" bg-dark py-0 text-center fs--1 light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                {'ITP Metrics - '}
                <a
                  className="text-white opacity-85"
                  href="https://wpsites.ucalgary.ca/itp-lab/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Individual and Team Performance Lab
                </a>
                <span className="d-none d-sm-inline-block">{' | '}</span>
                <br className="d-sm-none" />
                {` ${new Date().getFullYear()} `}
                &copy;
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                {`v${version}`}
              </p>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default FooterStandard;
