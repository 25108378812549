import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';

import { formatCompletionPercentage, formatTime } from '../../../helpers/itpmetricsUtils';

function InfoPopover({ item, alignment = 'right' }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Popover>
          <Popover.Body className="tooltip-popover">
            <Table borderless size="sm" className="tooltip-table">
              <tbody>
                <tr>
                  <td>Survey creator:</td>
                  <td>{item.createdBy}</td>
                </tr>
                <tr>
                  <td>Survey available at:</td>
                  <td>{formatTime(item.surveyStartedAt)}</td>
                </tr>
                <tr>
                  <td>Started at:</td>
                  <td>{formatTime(item.startedAt)}</td>
                </tr>
                <tr>
                  <td>Completed at:</td>
                  <td>{formatTime(item.completedAt)}</td>
                </tr>
                <tr>
                  <td>Completed %:</td>
                  <td>
                    {formatCompletionPercentage({
                      top: item.completedCount,
                      bottom: item.templateItemCount,
                    })}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Popover.Body>
        </Popover>
              )}
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        style={
          alignment === 'right'
            ? { position: 'absolute', right: '40px', marginTop: '5px' }
            : { marginLeft: '10px', marginTop: '5px' }
        }
      />
    </OverlayTrigger>
  );
}
InfoPopover.propTypes = {
  item: PropTypes.object,
  alignment: PropTypes.oneOf(['left', 'right']),
};

export default InfoPopover;
