import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import PeerTableRow from './PeerTableRow';

function PeerRanged({
  data,
  peers,
  response,
  onChanged,
}) {
  const items = data.filter((item) => item.type === 'peerRanged');
  if (!items || items.length === 0) {
    return null;
  }
  return (
    <>
      {items.map((item) => {
        const choices = Object.entries(item.choices);
        return (
          <div key={`peer-ranged-${item.key}`} className="pt-3 pb-3">
            <div className="p-2">{ReactHtmlParser(item.content)}</div>
            <Table responsive="sm">
              <thead>
                <tr>
                  <th> </th>
                  {choices.map((choice) => (
                    <th
                      key={`peer-ranged-header-${choice[0]}-${choice[1]}`}
                      style={{ fontSize: 12 }}
                      scope="col"
                      className="text-center"
                    >
                      {choice[1]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {peers.map((peer, index) => (
                  <PeerTableRow
                    key={`peer-ranged-table-row-${peer.respondentId}`}
                    scoringKey={item.key}
                    peer={peer}
                    response={response}
                    onChanged={onChanged}
                    hideFirstColumn={index === 0}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        );
      })}
    </>
  );
}

PeerRanged.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      choices: PropTypes.objectOf(PropTypes.string),
      content: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  peers: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    respondentId: PropTypes.string,
  })),
  response: PropTypes.object,
  onChanged: PropTypes.func,
};

PeerRanged.defaultProps = {
  data: [],
  peers: [],
  response: {},
  onChanged: {},
};

export default PeerRanged;
