import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

function Caption({ data }) {
  const items = data.filter((item) => item.type === 'caption');
  if (items.length === 0) {
    return null;
  }
  return items.map((item, index) => (
    <div className="pt-3 pb-3" key={`caption-${item.key || index}`}>
      {item.type === 'caption' && (ReactHtmlParser(item.content))}
      {item.type === 'captionHeader' && (<h5>{ReactHtmlParser(item.content)}</h5>)}
    </div>
  ));
}

Caption.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.string,
  })),
};

Caption.defaultProps = {
  data: [],
};

export default Caption;
