import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form, Row,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { adminSearch } from '../../../bridge';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ContentSection from '../common/ContentSection';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';
import AdminCard from './AdminCard';

function Admin() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const query = params.get('query') || '';

  const { user } = useAuthContext();
  const [inProgress, setInProgress] = useState(false);
  const [users, setUsers] = useState([]);
  const [respondents, setRespondents] = useState([]);
  const [surveys, setSurveys] = useState([]);

  const [formData, setFormData] = useState({
    query: '',
  });

  const handleSubmit = async (forceQuery) => {
    const q = forceQuery ?? formData.query.trim();
    setInProgress(true);
    const { response } = await adminSearch(q);
    setInProgress(false);
    setUsers(response.users);
    setRespondents(response.respondents);
    setSurveys(response.surveys);
    if (!forceQuery) {
      navigate(`?query=${q}`);
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (query !== formData.query) {
      setFormData({
        ...formData,
        query,
      });
      handleSubmit(query);
    }
  }, [query]);

  if (!user) {
    return <SignedOut />;
  }

  return (
    <Page>
      <ContentSection>
        <h4 className="pb-4">Admin Panel</h4>
        <Row className="pb-4">
          <Form.Group className="mb-3" as={Col} sm={6}>
            <Form.Control
              value={formData.query}
              name="query"
              onChange={handleFieldChange}
              type="text"
              required
              autoFocus
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          </Form.Group>
          <Col>
            <Button
              disabled={inProgress}
              onClick={handleSubmit}
            >
              Search
            </Button>
          </Col>
        </Row>

        {users.length > 0 && (
        <>
          <h4>Users</h4>
          <Row className="p-2 pb-4">
            {users.map((card) => (
              <Col
                className="px-0 col-same-height"
                key={`admin-card-user-${card.userId}`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <AdminCard card={card} />
              </Col>
            ))}
          </Row>
        </>
        )}

        {respondents.length > 0 && (
        <>
          <h4>Respondents</h4>
          <Row className="p-2 pb-4">
            {respondents.map((card) => (
              <Col
                className="px-0 col-same-height"
                key={`admin-card-respondent-${card.respondentId}`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <AdminCard card={card} />
              </Col>
            ))}
          </Row>
        </>
        )}

        {surveys.length > 0 && (
        <>
          <h4 className="mt-3">Surveys</h4>
          <Row className="p-2 pb-4">
            {surveys.map((card) => (
              <Col
                className="px-0 col-same-height"
                key={`admin-card-survey-${card.surveyId}`}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              >
                <AdminCard card={card} />
              </Col>
            ))}
          </Row>
        </>
        )}
      </ContentSection>
    </Page>
  );
}

export default Admin;
