export const version = '3.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  rememberedEmail: '',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3010/api',
  amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY || 'c3d06bb2feed670fad0bb4bbaa0a2066',
  NODE_ENV: process.env.NODE_ENV || 'development',
};

export default {
  version, navbarBreakPoint, topNavbarBreakpoint, settings,
};
