import PropTypes from 'prop-types';
import React from 'react';

import NavbarStandard from '../../common/navbar/NavbarStandard';

function Page({ children, unfixed }) {
  return (
    <>
      <NavbarStandard unfixed={unfixed} />
      {children}
    </>
  );
}

Page.propTypes = {
  unfixed: PropTypes.bool,
  children: PropTypes.node,
};

Page.defaultProps = {
  unfixed: true,
  children: null,
};

export default Page;
