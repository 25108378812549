/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { updateUserResponses } from '../../../bridge';

function CompleteModal({
  show, onHide, respondentId, completed,
}) {
  const navigate = useNavigate();

  const title = completed ? 'Survey Completed'
    : respondentId ? 'Complete Survey?'
      : 'Preview Complete';

  const body = completed ? 'Responses cannot be adjusted'
    : respondentId ? 'After completing this survey, responses cannot be adjusted.'
      : 'Thank you for previewing this survey.';

  const button = completed ? 'OK'
    : respondentId ? 'Complete Survey'
      : 'Home';

  const handleClick = async () => {
    if (completed) {
      navigate('/dashboard');
    } else if (respondentId) {
      await updateUserResponses(respondentId, {}, true);
      navigate(`/report/${respondentId}`);
    } else {
      navigate('/');
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        if (completed) {
          navigate('/dashboard');
        } else {
          onHide();
        }
      }}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick}>{button}</Button>
      </Modal.Footer>
    </Modal>
  );
}

CompleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  respondentId: PropTypes.string,
  completed: PropTypes.bool,
};

CompleteModal.defaultProps = {
  show: false,
  onHide: () => {},
  respondentId: null,
  completed: false,
};

export default CompleteModal;
