import axios from 'axios';

const post = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

const get = async (url, responseType) => {
  try {
    const response = await axios.get(url, {
      responseType,
    });
    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

const getWithAuth = async (url, responseType) => {
  try {
    const token = localStorage.getItem('token')
      || new URLSearchParams(document.location.search).get('token');
    if (!token) {
      return get(url);
    }
    const response = await axios.get(url, {
      responseType,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

const postWithAuth = async (url, data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

const putWithAuth = async (url, data) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

const deleteWithAuth = async (url) => {
  try {
    const token = localStorage.getItem('token');

    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
};

// endpoints that require the authorization token

export const saveProfile = async (user) => postWithAuth('/api/user/save-profile', user);
export const getPrivacyPolicy = async () => get('/api/privacy-policy');

export const getSurvey = async (surveyId) => getWithAuth(`/api/survey/${surveyId}`);
export const getUserSurveys = async () => getWithAuth('/api/survey');

export const createSurvey = async (survey) => postWithAuth('/api/survey', survey);
export const updateSurveyName = async (surveyId, name) => postWithAuth(`/api/survey/${surveyId}/name`, { name });
export const updateScheduledStart = async (surveyId, scheduledStartAt, releaseMode) => postWithAuth(`/api/survey/${surveyId}/scheduleStart`, { scheduledStartAt, releaseMode });
export const startSurvey = async (surveyId, releaseMode) => postWithAuth(`/api/survey/${surveyId}/start`, { releaseMode });
export const endSurvey = async (surveyId) => postWithAuth(`/api/survey/${surveyId}/end`);
export const deleteSurvey = async (surveyId) => postWithAuth(`/api/survey/${surveyId}/delete`);
export const sendSurveyNotifications = async (surveyId) => postWithAuth(`/api/survey/${surveyId}/notifications`);
export const downloadRawData = async (surveyId) => getWithAuth(`/api/survey/${surveyId}/download`, 'blob');
export const approveSurveyFeedback = async (surveyId, team) => postWithAuth(`/api/survey/${surveyId}/approveFeedback`, { team });
export const addManager = async (surveyId, email) => postWithAuth(`/api/survey/${surveyId}/manager`, { email });
export const deleteManager = async (surveyId, email) => postWithAuth(`/api/survey/${surveyId}/manager/delete`, { email });
export const generateRandomResponses = async (surveyId) => postWithAuth(`/api/survey/${surveyId}/random`);

export const createReview = async (review) => postWithAuth('/api/review', review);
export const updateReviewName = async (reviewId, name) => postWithAuth(`/api/review/${reviewId}/name`, { name });
export const getReviews = async () => getWithAuth('/api/review');
export const getReview = async (reviewId) => getWithAuth(`/api/review/${reviewId}`);
export const getReviewParticipant = async (reviewId, reviewParticipantId) => getWithAuth(`/api/review/${reviewId}/${reviewParticipantId}`);
export const addReviewParticipants = async (reviewId, participants) => postWithAuth(`/api/review/${reviewId}/participants`, { participants });
export const deleteReviewParticipant = async (reviewId, reviewParticipantId) => deleteWithAuth(`/api/review/${reviewId}/${reviewParticipantId}`);
export const deleteReview = async (reviewId) => deleteWithAuth(`/api/review/${reviewId}`);
export const sendReviewEmail = async (reviewId, reviewParticipantId) => getWithAuth(`/api/review/${reviewId}/${reviewParticipantId}/send`);
export const sendReviewNotifications = async (reviewId) => postWithAuth(`/api/review/${reviewId}/notifications`);

export const getRespondent = async (respondentId) => getWithAuth(`/api/respondent/${respondentId}`);
export const getUserResponses = async (respondentId) => getWithAuth(`/api/respondent/${respondentId}`);
export const addRespondents = async (surveyId, respondents) => postWithAuth(`/api/respondent/survey/${surveyId}`, respondents);
export const updateUserResponses = async (respondentId, response, completed) => putWithAuth(`/api/respondent/${respondentId}`, { response, completed });
export const updateReportResponses = async (respondentId, section, subSection, value) => putWithAuth(`/api/respondent/reportResponses/${respondentId}`, {
  section, subSection, value,
});
export const reopenRespondent = async (respondentId) => getWithAuth(`/api/respondent/${respondentId}/reopen`);
export const changeRespondentEmail = async (respondentId, email) => postWithAuth(`/api/respondent/${respondentId}/changeEmail`, { email });
export const sendRespondentEmail = async (respondentId) => getWithAuth(`/api/respondent/${respondentId}/send`);
export const deleteRespondent = async (surveyId, respondentId) => deleteWithAuth(`/api/respondent/survey/${surveyId}/respondent/${respondentId}`);

export const getReport = async (respondentId, reload) => getWithAuth(`/api/respondent/report/${respondentId}${reload ? '?reload=true' : ''}`);
export const downloadReport = async (respondentId) => getWithAuth(`/api/respondent/report/${respondentId}/download`, 'blob');
export const downloadLegacyReport = async (respondentId) => getWithAuth(`/api/legacy/report/${respondentId}/download`, 'blob');
export const getTemplateReport = async (templateId) => get(`/api/respondent/report/${templateId}/template`);
export const downloadTemplateReport = async (templateId) => get(`/api/respondent/report/${templateId}/template/download`, 'blob');
export const getLegacyReports = async () => getWithAuth('/api/legacy/reports');
export const getLegacyReport = async (respondentId) => getWithAuth(`/api/legacy/report/${respondentId}`);

export const renewSession = async () => postWithAuth('/api/user/renewSession');
export const adminSearch = async (query) => postWithAuth('/api/admin/search', { query });

// endpoints that don't require the authorization token
export const logIn = async (email, respondentId) => post('/api/user/logIn', { email, respondentId });
export const verifyCode = async (email, code) => post('/api/user/verify', { email, code });
export const getTemplate = async (templateId) => get(`/api/template/${templateId}`);
export const getLegacyFeedbackReview = async (key) => get(`/api/review/feedback/${key}`);
export const getRandomNames = async (count) => get(`/api/user/random/${count}`);
