import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Section from '../../common/Section';
import SectionHeader from './SectionHeader';

const processes = [
  {
    title: 'Collaboration',
    description:
      'To accomplish this goal, we work with organizations to develop and track key graduate attributes such as teamwork and communication skills, leadership readiness, and self-awareness.',
  },
  {
    title: 'Evidence-based',
    description:
      'We have developed an evidence-based suite of online teaching and assessment tools that build, track, and report on these graduate attributes.',
  },
  {
    title: 'Custom Solutions',
    description:
      'We work closely with organizations to create customized teaching and assessment tools to fit their unique needs.',
  },
];

function Processes() {
  return (
    <Section>
      <SectionHeader
        title="Team assessments, backed by research"
        subtitle="ITP Metrics aims to improve the effectiveness of individuals and teams around the world."
      />
      <Row className="my-7">
        { processes.map(({ title, description }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col lg={4} className="my-2" key={`landing-process-index-${index}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </Col>
        ))}
      </Row>

    </Section>
  );
}

export default Processes;
