import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowsRotate, faChartSimple, faEllipsisV, faPenToSquare, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import {
  deleteRespondent, reopenRespondent, sendRespondentEmail,
} from '../../../../bridge';
import { logError } from '../../../../helpers/logger';
import ChangeEmailModal from '../modals/ChangeEmailModal';

function RespondentCardDropDown({
  surveyId, onChanged, showPopup, setShowPopup, respondentId, status, surveyStatus, email,
}) {
  const completed = status === 'completed';
  const surveyEnded = surveyStatus === 'ended';
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);

  const handleToggleClick = (e) => {
    e.stopPropagation();
    setShowPopup(!showPopup);
  };

  const onClickViewReport = async () => {
    setShowPopup(false);
    window.open(`/report/${respondentId}`, '_blank', 'noreferrer');
  };

  const onClickDelete = async () => {
    setShowPopup(false);
    try {
      const { error } = await deleteRespondent(surveyId, respondentId);
      if (error) {
        logError('RespondentCardDropDown onClickDelete', error);
      } else {
        onChanged();
      }
      setShowPopup(false);
    } catch (error) {
      logError('RespondentCardDropDown onClickDelete catch', error);
    }
  };

  const onClickResend = async () => {
    setShowPopup(false);
    try {
      const { error } = await sendRespondentEmail(respondentId);
      if (error) {
        logError('RespondentCardDropDown onClickResend', error);
      } else {
        onChanged();
      }
      setShowPopup(false);
    } catch (error) {
      logError('RespondentCardDropDown onClickResend catch', error);
    }
  };

  const onClickOpenForRetake = async () => {
    if (!completed) {
      return;
    }
    setShowPopup(false);
    try {
      const { error } = await reopenRespondent(respondentId);
      if (error) {
        logError('RespondentCardDropDown onClickOpenForRetake', error);
      } else {
        onChanged();
      }
      setShowPopup(false);
    } catch (error) {
      logError('RespondentCardDropDown onClickOpenForRetake catch', error);
    }
  };

  const onClickChangeEmail = async () => {
    setShowPopup(false);
    setShowChangeEmailModal(true);
  };

  const options = [
    {
      key: 'viewReport',
      icon: faChartSimple,
      caption: 'View Report',
      disabled: !completed && !surveyEnded,
      callback: onClickViewReport,
    },
    {
      key: 'delete',
      icon: faTrashAlt,
      caption: 'Delete',
      disabled: completed,
      callback: onClickDelete,
    },
    {
      key: 'resend',
      icon: faPaperPlane,
      caption: 'Resend notification',
      disabled: completed || surveyEnded,
      callback: onClickResend,
    },
    {
      key: 'reopen',
      icon: faArrowsRotate,
      caption: 'Open for retake',
      disabled: !completed || surveyEnded,
      callback: onClickOpenForRetake,
    },
    {
      key: 'changeEmail',
      icon: faPenToSquare,
      caption: 'Change email',
      disabled: false,
      callback: onClickChangeEmail,
    },
  ];

  return (
    <>
      <Dropdown
        show={showPopup}
        onToggle={(isOpen) => setShowPopup(isOpen)}
      >
        <FontAwesomeIcon
          icon={faEllipsisV}
          onClick={handleToggleClick}
          className="p-1 cursor-pointer"
        />
        <Dropdown.Menu className="p-0" style={{ marginLeft: '-140px' }}>
          {options.map((option) => (
            <Dropdown.Item
              key={option.key}
              onClick={(e) => {
                e.stopPropagation();
                if (option.disabled) {
                  return;
                }
                option.callback(e);
              }}
              className="py-1 px-2 m-0"
              disabled={option.disabled}
              style={option.disabled
                ? { opacity: 0.4 }
                : { opacity: 1 }}
            >
              <FontAwesomeIcon icon={option.icon} className="me-2" />
              {option.caption}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {showChangeEmailModal && (
      <ChangeEmailModal
        email={email}
        onChanged={async () => {
          await onChanged();
          setShowChangeEmailModal(false);
        }}
        onCancel={() => setShowChangeEmailModal(false)}
        respondentId={respondentId}
      />
      )}
    </>
  );
}

RespondentCardDropDown.propTypes = {
  surveyId: PropTypes.string,
  onChanged: PropTypes.func,
  showPopup: PropTypes.bool,
  setShowPopup: PropTypes.func,
  respondentId: PropTypes.string,
  status: PropTypes.string,
  surveyStatus: PropTypes.string,
  email: PropTypes.string,
};

export default RespondentCardDropDown;
