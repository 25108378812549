import { faTriangleExclamation, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, isPlainObject, isString } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Col, Modal, Row, Table,
} from 'react-bootstrap';

import { approveSurveyFeedback } from '../../../../bridge';
import RespondentCard from '../components/RespondentCard';
import AddParticipantModal from './AddParticipantModal';
import EditResponseModal from './EditResponseModal';

function TeamParticipantsModal({
  teamName,
  respondents,
  surveyId,
  surveyStatus,
  onChanged,
  onClose,
  releaseMode,
  teamCompleted,
  notApproved,
}) {
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [showEditResponseModal, setShowEditResponseModal] = useState(false);
  const [editField, setEditField] = useState(null);
  const [editRespondent, setEditRespondent] = useState(null);
  const [editTeamMemberId, setEditTeamMemberId] = useState(null);
  const [showConfirmApproval, setShowConfirmApproval] = useState(false);

  const completedCount = respondents.filter(({ status }) => status === 'completed').length;

  const getPeerResponsesByField = (field, respondent) => Object.keys(respondent.response[field] || {})
    .map((respondentId) => ({
      feedback: isPlainObject(respondent.response[field])
        ? respondent.response[field][respondentId]
        : null,
      teamMember: respondents.find((r) => r.respondentId === respondentId),
      field,
    }))
    .filter((responses) => !!responses.teamMember && !!responses.feedback);

  const getResponsesByField = (field, respondent) => (isString(respondent.response[field])
    ? respondent.response[field]
    : null);

  const peerFeedback = respondents.map((respondent) => ({
    respondent,
    response: [
      ...getPeerResponsesByField('Qualitative', respondent),
      ...getPeerResponsesByField('QualitativePeer', respondent),
    ],
  }))
    .filter(({ response }) => response.length > 0);

  const teamFeedback = compact(respondents.map((respondent) => {
    const qualitativeResponse = getResponsesByField('Qualitative', respondent);
    const qualitativeTeamResponse = getResponsesByField('QualitativeTeam', respondent);
    if (!qualitativeResponse && !qualitativeTeamResponse) {
      return null;
    }
    return {
      respondent,
      response: qualitativeResponse || qualitativeTeamResponse,
      field: qualitativeResponse ? 'Qualitative' : 'QualitativeTeam',
    };
  }));

  const confidentialFeedback = respondents
    .filter(({ response }) => !!response.Confidential)
    .map((respondent) => ({
      respondent,
      response: respondent.response.Confidential,
    }));

  const onApprove = async () => {
    await approveSurveyFeedback(surveyId, teamName);
    await onChanged();
    onClose();
  };

  return (
    <>
      <Modal
        show
        centered
        dialogClassName="modal-xl"
        onHide={onClose}
        scrollable
      >
        <Modal.Header>
          <Modal.Title>{`Team ${teamName} Participants`}</Modal.Title>
          <FontAwesomeIcon
            icon={faUserPlus}
            onClick={() => setShowAddParticipantModal(true)}
            fontSize={25}
            className="cursor-pointer my-3 pe-2"
          />
        </Modal.Header>
        <Modal.Body>
          <Row className="pb-4">
            {respondents && (
              respondents.map((respondent) => (
                <Col
                  key={`team-participants-modal-respondent-${respondent.respondentId}`}
                  className="px-1 col-same-height"
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <RespondentCard
                    surveyId={surveyId}
                    surveyStatus={surveyStatus}
                    respondent={respondent}
                    onChanged={onChanged}
                  />
                </Col>
              ))
            )}
          </Row>
          {respondents.length < 3 && (
            <div className="text-danger pt-2" style={{ fontSize: '1.1em' }}>
              <hr />
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <span className="px-1">Requires at least 3 team members to generate reports</span>
              <div style={{ fontSize: '0.8em' }} className="pt-2 text-muted">
                {`Please add at least ${3 - respondents.length} more team member${3 - respondents.length > 1 ? 's' : ''}. `}
                Otherwise, reports will not be generated for this team.
              </div>
            </div>
          )}
          {surveyStatus === 'ended' && completedCount < 3 && (
            <div className="text-danger pt-2" style={{ fontSize: '1.1em' }}>
              <hr />
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <span className="px-1">Requires at least 3 completions to generate reports</span>
              <div style={{ fontSize: '0.8em' }} className="pt-2 text-muted">
                {`${completedCount === 0 ? 'No' : `Only ${completedCount}`} team member${completedCount === 1 ? '' : 's'} completed their survey. `}
                Reports cannot be generated for this team.
              </div>
            </div>
          )}
          {teamCompleted && notApproved && (
          <div className="text-danger pt-2" style={{ fontSize: '1.1em' }}>
            <hr />
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <span className="px-1">Requires feedback approval</span>
            <div style={{ fontSize: '0.8em' }} className="pt-2 text-muted">
              Please review and approve the peer feedback for team. Otherwise, reports will not be generated for this team.
            </div>
          </div>
          )}
          {confidentialFeedback.length > 0 && (
            <div>
              <hr />
              <div className="text-warning pt-2" style={{ fontSize: '1.1em' }}>
                <FontAwesomeIcon icon={faTriangleExclamation} />
                <span className="px-1">Feedback for survey creator</span>
              </div>
              <Table size="sm" style={{ fontSize: '0.8em' }}>
                <thead>
                  <tr>
                    <th width="15%">Participant</th>
                    <th width="85%">Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  {confidentialFeedback.map(({ respondent, response }) => (
                    <tr key={`team-participants-modal-confidential-feedback-${respondent.respondentId}`}>
                      <td>{`${respondent.firstName} ${respondent.lastName}`}</td>
                      <td className="fst-italic">{`${response}`}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {teamFeedback.length > 0 && (
            <div>
              <hr />
              <div className="pt-2" style={{ fontSize: '1.1em' }}>
                <span className="px-1">Team Feedback</span>
              </div>
              <Table size="sm" style={{ fontSize: '0.8em' }}>
                <thead>
                  <tr>
                    <th width="15%">Participant</th>
                    <th width="85%">Feedback</th>
                    {releaseMode === 'review' && notApproved
                        && (<th width="5%">Actions</th>)}
                  </tr>
                </thead>
                <tbody>
                  {teamFeedback.map(({ respondent, response, field }) => (
                    <tr key={`team-participants-modal-team-feedback-${respondent.respondentId}`}>
                      <td>{`${respondent.firstName} ${respondent.lastName}`}</td>
                      <td className="fst-italic">{`${response}`}</td>
                      {releaseMode === 'review' && notApproved && (
                      <td>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          className="btn-text"
                          onClick={() => {
                            setEditField(field);
                            setEditTeamMemberId(null);
                            setEditRespondent(respondent);
                            setShowEditResponseModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {peerFeedback.length > 0 && (
            <div>
              <hr />
              <div className="pt-2" style={{ fontSize: '1.1em' }}>
                <span className="px-1">Peer Feedback</span>
              </div>
              <Table size="sm" style={{ fontSize: '0.8em' }}>
                <thead>
                  <tr>
                    <th width="15%">Participant</th>
                    <th width="15%">Team Member</th>
                    <th width="65%">Feedback</th>
                    {releaseMode === 'review' && notApproved
                        && (<th width="5%">Actions</th>)}
                  </tr>
                </thead>
                <tbody>
                  {peerFeedback.map(({ respondent, response }) => response.map(({ teamMember, feedback, field }) => (
                    <tr key={`team-participants-modal-peer-feedback-${respondent.respondentId}-${teamMember.respondentId}-${field}`}>
                      <td>{`${respondent.firstName} ${respondent.lastName}`}</td>
                      <td>{`${teamMember.firstName} ${teamMember.lastName}`}</td>
                      <td className="fst-italic">{`${feedback}`}</td>
                      {releaseMode === 'review' && notApproved && (
                      <td>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          className="btn-text"
                          onClick={() => {
                            setEditField(field);
                            setEditTeamMemberId(teamMember.respondentId);
                            setEditRespondent(respondent);
                            setShowEditResponseModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </td>
                      )}
                    </tr>
                  )))}
                </tbody>
              </Table>
            </div>
          )}
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onClose}>
            Close
          </Button>
          {releaseMode === 'review' && notApproved && (
            <Button
              variant="primary"
              onClick={() => {
                if (teamCompleted || surveyStatus === 'ended') {
                  onApprove();
                } else {
                  setShowConfirmApproval(true);
                }
              }}
            >
              Approve
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {showAddParticipantModal && (
        <AddParticipantModal
          surveyId={surveyId}
          onCancel={() => setShowAddParticipantModal(false)}
          onChanged={async () => {
            setShowAddParticipantModal(false);
            await onChanged();
          }}
          teamName={teamName}
        />
      )}
      {showEditResponseModal && (
        <EditResponseModal
          respondent={editRespondent}
          responseKey={editField}
          teamMemberId={editTeamMemberId}
          onClose={() => setShowEditResponseModal(false)}
          onChanged={onChanged}
        />
      )}
      {showConfirmApproval && (
        <Modal show>
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            There are incomplete team members. Any future responses will be automatically approved.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => setShowConfirmApproval(false)}>
              Cancel
            </Button>
            <Button onClick={() => {
              setShowConfirmApproval(false);
              onApprove();
            }}
            >
              Approve
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

TeamParticipantsModal.propTypes = {
  respondents: PropTypes.array,
  teamName: PropTypes.string,
  surveyId: PropTypes.string,
  onChanged: PropTypes.func,
  onClose: PropTypes.func,
  releaseMode: PropTypes.string,
  teamCompleted: PropTypes.bool,
  notApproved: PropTypes.bool,
  surveyStatus: PropTypes.string,
};

export default TeamParticipantsModal;
