import { useContext } from 'react';

import AppContext from '../context/Context';
import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
  const { setConfig } = useContext(AppContext);
  const { dispatch } = useAuthContext();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setConfig('verifyUser', null);
    dispatch({ type: 'LOGOUT' });
    window.location = '/';
  };

  return { logout };
};
