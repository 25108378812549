/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ButtonGroup,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { generateRandomResponses, getSurvey } from '../../../../bridge';
import { formatScheduledTime } from '../../../../helpers/itpmetricsUtils';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import ContentSection from '../../common/ContentSection';
import InfoPopover from '../../common/InfoPopover';
import NotFound from '../../common/NotFound';
import Page from '../../common/Page';
import SignedOut from '../../common/SignedOutDialog';
import AddManagerButton from '../components/AddManagerButton';
import AddParticipantButton from '../components/AddParticipantButton';
import DeleteSurveyButton from '../components/DeleteSurveyButton';
import DownloadRawData from '../components/DownloadRawData';
import EditSurveyNameButton from '../components/EditSurveyNameButton';
import EndSurveyButton from '../components/EndSurveyButton';
import PreviewSurveyButton from '../components/PreviewSurveyButton';
import SendNotificationsButton from '../components/SendNotificationsButton';
import StartSurveyButton from '../components/StartSurveyButton';
import SurveyDashboardCards from '../components/SurveyDashboardCards';
import UpdateScheduledTimeButton from '../components/UpdateScheduledTimeButton';
import UploadParticipantsButton from '../components/UploadParticipantsButton';

export default function SurveyDashboard() {
  const { user } = useAuthContext();
  const { surveyId } = useParams();
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [respondents, setRespondents] = useState([]);
  const [template, setTemplate] = useState(null);
  const [teams, setTeams] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(null);
  const [isDemoSurvey, setIsDemoSurvey] = useState(false);
  const [demoNeedsResponses, setDemoNeedsResponses] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const onChanged = async (id = surveyId) => {
    const { response, error: e } = await getSurvey(id);
    if (e) {
      setError(e);
      return;
    }
    setSurvey(response.survey);
    setRespondents(response.respondents || []);
    setTemplate(response.template);
  };

  const onClickGenerateResponses = async () => {
    setIsGenerating(true);
    await generateRandomResponses(surveyId);
    onChanged();
    setIsGenerating(false);
  };

  useEffect(() => {
    if (error || survey) {
      return;
    }

    onChanged();
  }, [survey, surveyId, error]);

  useEffect(() => {
    if (respondents.length) {
      const groupedByTeam = {};
      respondents.forEach((item) => {
        if (!item.team) {
          return;
        }
        groupedByTeam[item.team] = groupedByTeam[item.team] || [];
        groupedByTeam[item.team].push(item);
      });
      setTeams(groupedByTeam);

      if (respondents.filter(({ email }) => email.endsWith('@donotreply.com')).length
        === respondents.length
      ) {
        setDemoNeedsResponses(
          respondents.filter(({ status }) => status !== 'completed').length === respondents.length,
        );
        setIsDemoSurvey(true);
      }
    }
  }, [respondents]);

  useEffect(() => {
    if (!refreshTimer) {
      setRefreshTimer(setInterval(onChanged, 60 * 1000));
    }
  }, [survey]);

  if (!user) {
    return <SignedOut />;
  }

  if (error) {
    return <NotFound subject="survey" />;
  }

  return (
    <Page>
      <ContentSection>
        <div>
          <span className="survey-dashboard-name">{survey?.name}</span>
          {survey && (<InfoPopover item={survey} alignment="left" />)}
        </div>

        {survey && (
        <div>
          <div className="pb-3 pt-3 border-secondary">
            <Row md="auto" className="px-0">
              <ButtonGroup>
                <PreviewSurveyButton survey={survey} />
                <EditSurveyNameButton survey={survey} onChanged={onChanged} />
                <AddParticipantButton survey={survey} template={template} onChanged={onChanged} />
                <UploadParticipantsButton
                  survey={survey}
                  template={template}
                  onChanged={onChanged}
                />
                <SendNotificationsButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={!respondents.find((r) => r.status !== 'completed')}
                />
                <AddManagerButton
                  survey={survey}
                  onChanged={onChanged}
                />
                <DownloadRawData survey={survey} />
                <DeleteSurveyButton
                  survey={survey}
                  onDeleted={() => { window.location = '/dashboard'; }}
                />
                <StartSurveyButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status === 'in-progress' || respondents.length === 0}
                />
                <UpdateScheduledTimeButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status === 'in-progress' || respondents.length === 0}
                />
                <EndSurveyButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status !== 'in-progress'}
                />
              </ButtonGroup>
            </Row>
          </div>

          {survey.status === 'in-progress' && (
          <Alert variant="success" className="px-2 py-1">
            Survey is in-progress. It is open for participant responses.
          </Alert>
          )}

          {survey.status === 'ended' && (
          <Alert variant="danger" className="px-2 py-1">
            Survey has ended. It is closed for participant responses.
          </Alert>
          )}

          {survey.scheduledStartAt && (
          <Alert variant="info" className="px-2 py-1">
            {`Survey is scheduled to start at ${formatScheduledTime(survey.scheduledStartAt)}.`}
          </Alert>
          )}

          {survey.status === 'not-started' && respondents.length > 0 && !survey.scheduledStartAt && (
            <Alert variant="warning" className="px-2 py-1">
              {'Survey has not started. Click on '}
              <StartSurveyButton demo />
              {' to start the survey or '}
              <UpdateScheduledTimeButton demo />
              {' to schedule the survey.'}
            </Alert>
          )}

          {respondents.length > 0 && isDemoSurvey && demoNeedsResponses && survey.status !== 'not-started' && !isGenerating && (
            <Alert variant="warning" className="px-2 py-1">
              {'Click '}
              <a style={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={onClickGenerateResponses}>here</a>
              {' to generate to random responses for demo participants.'}
            </Alert>
          )}

          {respondents.length === 0 && (
          <Alert variant="warning" className="px-2 py-1">
            {'This survey does not have any participants. Click on '}
            <AddParticipantButton demo />
            {' or '}
            <UploadParticipantsButton demo />
            {' to add participants.'}
          </Alert>
          )}

          {respondents.length > 0 && (
            <SurveyDashboardCards
              respondents={respondents}
              template={template}
              surveyId={surveyId}
              onChanged={onChanged}
              teams={teams}
              releaseMode={survey.releaseMode}
              surveyStatus={survey.status}
            />
          )}

        </div>
        )}
      </ContentSection>
    </Page>
  );
}
