import { isBefore, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';

import { renewSession } from '../bridge';
import Admin from '../components/pages/admin/Admin';
import UnexpectedError from '../components/pages/common/UnexpectedError';
import Dashboard from '../components/pages/dashboard/Dashboard';
import Landing from '../components/pages/landing/Landing';
import Profile from '../components/pages/profile/Profile';
import FeedbackReview from '../components/pages/report/FeedbackReview';
import LegacyReport from '../components/pages/report/LegacyReport';
import Report from '../components/pages/report/Report';
import Preview from '../components/pages/survey/PreviewTemplate';
import Survey from '../components/pages/survey/Survey';
import ReviewDashboard from '../components/pages/survey-dashboard/pages/ReviewDashboard';
import SelectReviewTemplate from '../components/pages/survey-dashboard/pages/SelectReviewTemplate';
import SelectTemplate from '../components/pages/survey-dashboard/pages/SelectTemplate';
import SurveyDashboard from '../components/pages/survey-dashboard/pages/SurveyDashboard';
import { logError } from '../helpers/logger';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogin } from '../hooks/useLogin';

function FallbackComponent({ error }) {
  const { user } = useAuthContext();
  logError('ErrorBoundary', {
    user,
    error: error.message,
    stack: error.stack,
  });

  return (
    <UnexpectedError />
  );
}
FallbackComponent.propTypes = {
  error: PropTypes.object,
};

function AppRoutes() {
  const { dispatch } = useAuthContext();
  const { handleDispatch } = useLogin();

  const logoutCheck = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      return;
    }
    if (isBefore(user.expiry, new Date())) {
      localStorage.removeItem('user');
      dispatch({ type: 'LOGOUT' });
      return;
    }

    if (isBefore(sub(user.expiry, { days: 15 }), new Date())) {
      const { error, response } = await renewSession();
      if (!error) {
        handleDispatch(response.token);
      }
    }
  };

  useEffect(() => {
    logoutCheck();
    setInterval(logoutCheck, 60 * 1000);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/login" element={<Landing login />} />
        <Route path="/verify" element={<Landing verify />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/logout" element={<Landing logout />} />
        <Route path="/survey/create" element={<SelectTemplate />} />
        <Route path="/survey/:surveyId" element={<SurveyDashboard />} />
        <Route path="/template/:templateId/:pageIndex" element={<Preview />} />
        <Route path="/respondent/:respondentId/:pageIndex" element={<Survey />} />
        <Route path="/report/:respondentId" element={<Report />} />
        <Route path="/report/:templateId/template" element={<Report />} />
        <Route path="/review/create" element={<SelectReviewTemplate />} />
        <Route path="/review/:reviewId" element={<ReviewDashboard />} />
        <Route path="/review/feedback/:key" element={<FeedbackReview />} />
        <Route path="/trend/feedback/:key" element={<FeedbackReview />} />
        <Route path="/legacy/report/:respondentId" element={<LegacyReport />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default AppRoutes;
