import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../../../hooks/useAuthContext';
import ContentSection from '../../common/ContentSection';
import NotFound from '../../common/NotFound';
import Page from '../../common/Page';
import SignedOut from '../../common/SignedOutDialog';
import SelectReviewModal from '../components/SelectReviewModal';

export default function SelectReviewTemplate() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  if (!user) {
    return <SignedOut />;
  }

  if (!user.features.includes('createFeedbackReview')) {
    return <NotFound subject="feature" />;
  }

  return (
    <Page>
      <ContentSection>
        <SelectReviewModal onReviewSelected={async (newReviewId) => {
          navigate(`/review/${newReviewId}`);
        }}
        />
        <br />
      </ContentSection>
    </Page>
  );
}
