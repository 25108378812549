import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import SurveyNameModal from '../modals/SurveyNameModal';

function EditSurveyNameButton({ survey, onChanged }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        title="Edit survey name"
        size="md"
        variant="light"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
      {showModal && (
      <SurveyNameModal
        name={survey.name}
        onChanged={async () => {
          await onChanged();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        surveyId={survey.surveyId}
      />
      )}
    </>

  );
}

EditSurveyNameButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onChanged: PropTypes.func,
};

export default EditSurveyNameButton;
