import PropTypes from 'prop-types';
import React from 'react';

import Section from '../../common/Section';

function ContentSection({ children, image, tightSpacing }) {
  return (
    <Section
      className={`py-0 overflow-hidden light ${image || tightSpacing ? '' : 'my-5'}`}
      position="center bottom"
      image={image}
      overlay
      containerClassname="print-no-background"
    >
      {children}
    </Section>
  );
}
ContentSection.propTypes = {
  children: PropTypes.node,
  image: PropTypes.any,
  tightSpacing: PropTypes.bool,
};

ContentSection.defaultProps = {
  children: null,
  image: null,
  tightSpacing: false,
};
export default ContentSection;
