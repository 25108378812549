import PropTypes from 'prop-types';
import React from 'react';

import { ReportBarChartSection } from './ReportBarChartSection';
import { ReportCaptionSection } from './ReportCaptionSection';
import { ReportConflictChartSection } from './ReportConflictChartSection';
import { ReportDebriefSection } from './ReportDebriefSection';
import { ReportDiscussionSection } from './ReportDiscussionSection';
import { ReportPeerFeedbackExpressSection } from './ReportPeerFeedbackExpressSection';
import { ReportPeerFeedbackMedianSection } from './ReportPeerFeedbackMedianSection';
import { ReportPersonalityChartSection } from './ReportPersonalityChartSection';
import { ReportTeamDynamicsChartSection } from './ReportTeamDynamicsChartSection';
import { ReportTitleSection } from './ReportTitleSection';

const typeComponentMap = {
  caption: ReportCaptionSection,
  personalityReport: ReportPersonalityChartSection,
  teamDynamicsReport: ReportTeamDynamicsChartSection,
  conflictReport: ReportConflictChartSection,
  reportChart: ReportBarChartSection,
  debrief: ReportDebriefSection,
  discussion: ReportDiscussionSection,
  peerFeedbackExpress: ReportPeerFeedbackExpressSection,
  peerFeedbackMedian: ReportPeerFeedbackMedianSection,
};

function ReportBody({
  reportSections, respondent, updateResponse, templateMode, disableReportResponse,
}) {
  if (!respondent.report) {
    return null;
  }
  return (
    <>
      <ReportTitleSection respondent={respondent} templateMode={templateMode} />
      {reportSections.map((section) => {
        const key = `report-section-${section.type}-${section.key}`;
        const MappedComponent = typeComponentMap[section.type];
        if (MappedComponent) {
          return (
            <MappedComponent
              key={key}
              respondent={respondent}
              section={section}
              updateResponse={updateResponse}
              disableReportResponse={disableReportResponse}
            />
          );
        }
        return null;
      })}
    </>
  );
}

ReportBody.propTypes = {
  reportSections: PropTypes.array,
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
  updateResponse: PropTypes.func,
  templateMode: PropTypes.bool,
  disableReportResponse: PropTypes.bool,
};

ReportBody.defaultProps = {
  reportSections: null,
  respondent: { report: null },
  updateResponse: () => {},
  templateMode: false,
  disableReportResponse: false,
};

export default ReportBody;
