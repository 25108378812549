import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { DebouncedTextArea } from '../common/DebouncedTextArea';

export function ReportDiscussionSection({
  respondent, section, updateResponse, disableReportResponse,
}) {
  if (disableReportResponse) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  const { reportResponse = {} } = respondent;
  return (
    <Card className="pagebreak py-3 my-3">
      <Card.Body>
        <div className="m-3 p-3">
          {section.title && <h2>{section.title}</h2>}
          {section.prompt && (
            <>
              <br />
              <div>{ReactHtmlParser(section.prompt)}</div>
            </>
          )}
        </div>
        {section.sections.map(
          (subSection) => (
            <DebouncedTextArea
              key={`report-section-reportText-${section.key}-${subSection.key}`}
              originalValue={(reportResponse[section.key] || {})[subSection.key] || ''}
              scoringKey={subSection.key}
              content={subSection.content}
              onChanged={(key, _id, value) => updateResponse(section.key, key, value)}
            />
          ),
        )}
      </Card.Body>
    </Card>
  );
}

ReportDiscussionSection.propTypes = {
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
  section: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    prompt: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })),
  }),
  updateResponse: PropTypes.func,
  disableReportResponse: PropTypes.bool,
};

ReportDiscussionSection.defaultProps = {
  respondent: { report: null },
  section: null,
  updateResponse: () => {},
  disableReportResponse: false,
};
