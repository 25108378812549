import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import { DebouncedTextArea } from '../common/DebouncedTextArea';

export function ReportDebriefSection({ respondent, updateResponse, disableReportResponse }) {
  if (disableReportResponse) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  const { report: { teamMembers }, reportResponse = {} } = respondent;
  return (
    <div className="pagebreak">
      {teamMembers.map((member) => (
        <Card className="mt-3" key={`team-debrief-${member.respondentId}`}>
          <Card.Body>
            <h4 className="p-3 m-3">{`${member.firstName || ' '} ${member.lastName}`}</h4>
            <DebouncedTextArea
              content="Strengths"
              originalValue={(reportResponse[member.respondentId] || {}).strengths || ''}
              onChanged={(key, _id, value) => updateResponse(member.respondentId, 'strengths', value)}
            />
            <DebouncedTextArea
              content="Development"
              originalValue={(reportResponse[member.respondentId] || {}).development || ''}
              onChanged={(key, _id, value) => updateResponse(member.respondentId, 'development', value)}
            />
            <DebouncedTextArea
              content="Others"
              originalValue={(reportResponse[member.respondentId] || {}).others || ''}
              onChanged={(key, _id, value) => updateResponse(member.respondentId, 'others', value)}
            />
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

ReportDebriefSection.propTypes = {
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
  updateResponse: PropTypes.func,
  disableReportResponse: PropTypes.bool,
};

ReportDebriefSection.defaultProps = {
  respondent: { report: null },
  updateResponse: () => {},
  disableReportResponse: false,
};
