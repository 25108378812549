import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const tileOrder = [
  'Dominating',
  'Integrating',
  'Avoiding',
  'Accommodating',
  'Compromising',
];

export const labelPlacement = {
  Dominating: 'top',
  Integrating: 'top',
  Avoiding: 'bottom',
  Accommodating: 'bottom',
  Compromising: 'top',
};

export const keyColors = {
  Dominating: '#030e1e',
  Integrating: '#828c9a',
  Avoiding: '#222b39',
  Accommodating: '#293a55',
  Compromising: '#a0a29e',
};

export function ReportConflictConcernChart({ report, activeKey }) {
  const tileOrderedEntries = tileOrder.map((childKey) => ({ childKey, childValue: report[childKey] }));

  const getBackground = (key) => {
    if (!activeKey || key === activeKey) {
      return keyColors[key];
    }
    return 'white';
  };

  return (
    <table className="report-conflict-chart-table report-conflict-chart-center-header me-6">
      <tbody>
        <tr>
          <td>{' '}</td>
          <td>
            <table width="100%">
              <tbody>
                <tr><td colSpan={2} className="report-conflict-chart-table-label"><h4>Concern for Others</h4></td></tr>
                <tr>
                  <td className="report-conflict-chart-table-label">Low</td>
                  <td className="report-conflict-chart-table-label">High</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td rowSpan={2} className="report-conflict-chart-table-label report-conflict-chart-rotate">
                    <h4>Concern for Self</h4>
                  </td>
                  <td className="report-conflict-chart-table-label report-conflict-chart-rotate report-conflict-chart-table-cell">
                    High
                  </td>
                </tr>
                <tr>
                  <td className="report-conflict-chart-table-label report-conflict-chart-rotate report-conflict-chart-table-cell">
                    Low
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <Row>
              <Col className="report-conflict-chart-container-col">
                <div className="report-conflict-chart-container justify-content-center">
                  {tileOrderedEntries.map(
                    ({ childKey }) => (
                      <div
                        key={`report-conflictmanagement-${childKey}-${activeKey}`}
                        className={classNames([
                          'report-conflict-chart-shape',
                          { 'report-center-shape': childKey === 'Compromising' },
                          { 'report-conflict-chart-shape-primary': childKey === activeKey },
                        ])}
                        style={{ backgroundColor: getBackground(childKey) }}
                      >
                        {labelPlacement[childKey] === 'top'
                        && (
                          <h4 className="report-center-text mt-3">
                              {childKey}
                          </h4>
                        )}

                        {labelPlacement[childKey] === 'bottom'
                        && (
                        <h4 className="report-center-text report-center-text-bottom mt-6">
                          {childKey}
                        </h4>
                        )}
                      </div>
                    ),
                  )}
                </div>
              </Col>
            </Row>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

ReportConflictConcernChart.propTypes = {
  activeKey: PropTypes.string,
  isTemplate: PropTypes.bool,
  report: PropTypes.shape({
    Dominating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Avoiding: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Integrating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Accommodating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Compromising: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
  }),
};
