import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'react-bootstrap';

export const StatusMap = {
  'not-started': {
    color: 'light',
    text: 'Not Started',
    textVariant: 'secondary',
  },
  'in-progress': {
    color: 'secondary',
    text: 'In Progress',
    textVariant: 'light',
  },
  completed: {
    color: 'success',
    text: 'Completed',
    textVariant: 'light',
  },
  ended: {
    color: 'success',
    text: 'Ended',
    textVariant: 'light',
  },
};

function StatusPill({ status, surveyStatus }) {
  let bgVariant = StatusMap[status]?.color || 'light';
  let textVariant = StatusMap[status]?.textVariant || 'secondary';
  let text = StatusMap[status]?.text;
  if (surveyStatus && surveyStatus === 'ended' && status !== 'completed') {
    text = 'Incomplete - Survey Closed';
    bgVariant = 'light';
    textVariant = 'secondary';
  }
  return (
    <Badge pill bg={bgVariant} text={textVariant}>{text}</Badge>
  );
}

StatusPill.propTypes = {
  status: PropTypes.string,
  surveyStatus: PropTypes.string,
};

export default StatusPill;
