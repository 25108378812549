import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormCheck, Table } from 'react-bootstrap';

function TableRow({
  info, response, setResponse, changes, setChanges,
}) {
  if (info.type === 'multiplechoice') {
    const { choices } = info;
    const choiceArray = Object.entries(choices);
    const handleChange = (e) => {
      const { name, value } = e.target;
      const newResponse = { ...response };
      const newChanges = { ...changes };
      newResponse[name] = value;
      newChanges[name] = value;
      setResponse(newResponse);
      setChanges(newChanges);
    };
    return (
      <tr>
        <th>{info.content}</th>
        {choiceArray.map((choice) => (
          <td key={choice[0]}>
            <FormCheck
              className="text-center"
              type="radio"
              key={`lrg-choice-${info.key}-${choice[0]}`}
              name={`${info.key}`}
              id={`choice-${info.key}-${choice}`}
              value={choice[0]}
              onChange={handleChange}
              checked={response && response[info.key] === choice[0]}
            />
          </td>
        ))}
      </tr>
    );
  }
}

TableRow.propTypes = {
  info: PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
    content: PropTypes.string,
    choices: PropTypes.objectOf(PropTypes.string),
  }),
  response: PropTypes.object,
  setResponse: PropTypes.func,
  changes: PropTypes.object,
  setChanges: PropTypes.func,
};

TableRow.defaultProps = {
  info: {},
  response: {},
  setResponse: () => {},
  changes: {},
  setChanges: () => {},
};

function SmallDisplay({
  data, response, setResponse, changes, setChanges,
}) {
  const { choices } = data[1];
  const choiceArray = Object.entries(choices);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newResponse = { ...response };
    const newChanges = { ...changes };
    newResponse[name] = value;
    newChanges[name] = value;
    setResponse(newResponse);
    setChanges(newChanges);
  };
  return (
    <div>
      {data.map((row) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <span key={`sml-row-${row.key}`}>
          <p className="p-2">{row.content}</p>
          {row.type === 'multiplechoice'
              && choiceArray.map((choice) => (
                <FormCheck
                  className="mx-4"
                  name={row.key}
                  id={`choice-${row.key}-${choice[0]}`}
                  key={`sml-choice-${row.key}-${choice[0]}`}
                  type="radio"
                  value={choice[0]}
                  label={choice[1]}
                  onChange={handleChange}
                  checked={response && response[row.key] === choice[0]}
                />
              ))}
        </span>
      ))}
    </div>
  );
}

SmallDisplay.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
    content: PropTypes.string,
    choices: PropTypes.objectOf(PropTypes.string),
  })),
  response: PropTypes.object,
  setResponse: PropTypes.func,
  changes: PropTypes.object,
  setChanges: PropTypes.func,
};

SmallDisplay.defaultProps = {
  data: [],
  response: {},
  setResponse: () => {},
  changes: {},
  setChanges: () => {},
};

function LargeTable({
  data, response, setResponse, changes, setChanges,
}) {
  const { choices } = data[1];
  const choiceArray = Object.entries(choices);
  return (
    <Table className="p-3" responsive="sm">
      <thead>
        <tr>
          <th> </th>
          {choiceArray.map((choice) => (
            <th
              style={{ fontSize: 12 }}
              key={choice[0]}
              scope="col"
              className="text-center"
            >
              {choice[1]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <TableRow
            info={row}
            key={`lrg-row-${row.key}`}
            response={response}
            setResponse={setResponse}
            changes={changes}
            setChanges={setChanges}
          />
        ))}
      </tbody>
    </Table>
  );
}

LargeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
    content: PropTypes.string,
    choices: PropTypes.objectOf(PropTypes.string),
  })),
  response: PropTypes.object,
  setResponse: PropTypes.func,
  changes: PropTypes.object,
  setChanges: PropTypes.func,
};

LargeTable.defaultProps = {
  data: [],
  response: {},
  setResponse: () => {},
  changes: {},
  setChanges: () => {},
};

function MultipleChoice({
  data, response, setResponse, changes, setChanges,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const items = data.filter((item) => item.type === 'multiplechoice');
  if (items.length === 0) {
    return null;
  }

  if (width > 800) {
    return (
      <LargeTable
        data={items}
        response={response}
        setResponse={setResponse}
        changes={changes}
        setChanges={setChanges}
      />
    );
  }

  return (
    <SmallDisplay
      data={items}
      response={response}
      setResponse={setResponse}
      changes={changes}
      setChanges={setChanges}
    />
  );
}

MultipleChoice.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
    content: PropTypes.string,
    choices: PropTypes.objectOf(PropTypes.string),
  })),
  response: PropTypes.object,
  setResponse: PropTypes.func,
  changes: PropTypes.object,
  setChanges: PropTypes.func,
};

MultipleChoice.defaultProps = {
  data: [],
  response: {},
  setResponse: () => {},
  changes: {},
  setChanges: () => {},
};

export default MultipleChoice;
