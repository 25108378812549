import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { DebouncedTextArea } from '../../../common/DebouncedTextArea';

function TeamText({
  data,
  response,
  onChanged,
}) {
  const items = data.filter(({ type }) => type === 'teamText' || type === 'confidentialText');
  if (items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item) => (
        <div key={`team-text-${item.key}`}>
          <div className="p-3">{ReactHtmlParser(items[0].content)}</div>
          <DebouncedTextArea
            key={`team-text-area-${item.key}`}
            originalValue={response[item.key] || ''}
            scoringKey={item.key}
            onChanged={(key, _id, value) => onChanged({ [key]: value })}
          />
        </div>

      ))}
    </>
  );
}

TeamText.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  response: PropTypes.object,
  onChanged: PropTypes.func,
};

TeamText.defaultProps = {
  data: [],
  response: {},
  onChanged: () => {},
};

export default TeamText;
