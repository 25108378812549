// eslint-disable-next-line import/no-unresolved
import './DebouncedTextArea.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col, Form, FormCheck, Row,
} from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

export function DebouncedTextArea({
  originalValue, scoringKey, respondentId, content, unfamiliarCheckbox, onChanged,
}) {
  const maxLength = 3000;
  const [value, setValue] = useState(originalValue);
  const [savedTime, setSavedTime] = useState(null);
  const [characterCount, setCharacterCount] = useState(originalValue?.length || 0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value === 'N/A');
  }, [value]);

  const toggleCheckbox = () => {
    if (!checked) {
      setValue('N/A');
    } else {
      setValue('');
    }
    onChanged(scoringKey, respondentId, 'N/A');
    setSavedTime(new Date());
  };

  return (
    <Form.Group
      key={`debounced-text-area-${scoringKey}-${respondentId}`}
      className={classNames([
        'report-section-report-text',
        { 'p-3 m-3': !!content },
      ])}
    >
      {!unfamiliarCheckbox
        ? <Form.Label>{content}</Form.Label>
        : (
          <Row>
            <Col><Form.Label>{content}</Form.Label></Col>
            <Col>
              <FormCheck
                className="float-end"
                type="checkbox"
                label={(
                  <span onClick={toggleCheckbox}>
                    Not familiar with this team member's behavior
                  </span>
                )}
                onChange={toggleCheckbox}
                checked={checked}
                aria-label="Not familiar with this team member's behavior"
              />
            </Col>
          </Row>
        ) }
      <DebounceInput
        aria-label="Feedback Text Input"
        className="no-print form-control"
        element="textarea"
        rows={5}
        debounceTimeout={500}
        maxLength={maxLength}
        value={originalValue}
        onChange={(e) => {
          setValue(e.target.value);
          onChanged(scoringKey, respondentId, e.target.value);
          setSavedTime(new Date());
        }}
        onKeyUp={(e) => {
          setCharacterCount(e.target.value.length);
          setChecked(e.target.value === 'N/A');
        }}
      />
      <Row className="no-print report-row-notification">
        <Col>
          <small
            key={`debounced-text-area-counter-${scoringKey}-${respondentId}`}
            className="my-2 report-section-notification"
          >
            {characterCount !== undefined && `${characterCount} / ${maxLength}`}
          </small>
        </Col>
        <Col>
          <small
            key={`debounced-text-area-time-${scoringKey}-${respondentId}`}
            className="my-2 report-section-notification d-flex justify-content-end "
          >
            {savedTime && `Saved at ${savedTime.toLocaleTimeString()}`}
          </small>
        </Col>
      </Row>
      <p className="print-only">
        <span>{value}</span>
      </p>
    </Form.Group>
  );
}

DebouncedTextArea.propTypes = {
  originalValue: PropTypes.string,
  scoringKey: PropTypes.string,
  respondentId: PropTypes.string,
  content: PropTypes.string,
  onChanged: PropTypes.func,
  unfamiliarCheckbox: PropTypes.bool,
};

DebouncedTextArea.defaultProps = {
  originalValue: '',
  scoringKey: '',
  respondentId: '',
  content: '',
  onChanged: () => {},
  unfamiliarCheckbox: false,
};
