import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../../../hooks/useAuthContext';
import ContentSection from '../../common/ContentSection';
import Page from '../../common/Page';
import SignedOut from '../../common/SignedOutDialog';
import SelectTemplateModal from '../components/SelectTemplateModal';

export default function SelectTemplate() {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  if (!user) {
    return <SignedOut />;
  }

  return (
    <Page>
      <ContentSection>
        <SelectTemplateModal onTemplateSelected={async (newSurveyId) => {
          navigate(`/survey/${newSurveyId}`);
        }}
        />
        <br />
      </ContentSection>
    </Page>
  );
}
