import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert, Button, Form, Modal,
} from 'react-bootstrap';
import validator from 'validator';

import { changeRespondentEmail } from '../../../../bridge';
import { logError } from '../../../../helpers/logger';

function ChangeEmailModal({
  respondentId, email, onChanged, onCancel,
}) {
  const [text, setText] = useState(email);
  const [invalidEmail, setInvalidEmail] = useState(null);

  const onClickSave = async () => {
    const cleanedText = text.trim();
    if (!validator.isEmail(cleanedText) || validator.isEmpty(cleanedText)) {
      setInvalidEmail('Invalid email');
      return;
    }

    const { error } = await changeRespondentEmail(respondentId, cleanedText);
    if (error) {
      logError('ChangeEmailModal onClickSave', error);
      setInvalidEmail(error);
    } else {
      setInvalidEmail(null);
      onChanged();
    }
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>Change Participant Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="valid-email">
          <Form.Control
            type="email"
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            onFocus={(e) => e.target.select()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickSave();
              }
            }}
          />
        </Form.Group>
        {invalidEmail && (
          <Alert variant="danger">{invalidEmail}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onClickSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ChangeEmailModal.propTypes = {
  email: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
  respondentId: PropTypes.string,
};

export default ChangeEmailModal;
