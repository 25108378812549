import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { deleteReview } from '../../../../bridge';

function DeleteReviewButton({ reviewId, onDeleted }) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const onClickDelete = async () => {
    setInProgress(true);
    await deleteReview(reviewId);
    setShowModal(false);
    setInProgress(false);
    onDeleted();
  };

  return (
    <>
      <Button
        title="Delete review"
        size="md"
        variant="light"
        onClick={() => setShowModal(true)}
        disabled={inProgress}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>Delete Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will permanently delete the the review.
          <br />
          <br />
          <strong>This deletion is NOT reversible.</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" disabled={inProgress} onClick={onClickDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

DeleteReviewButton.propTypes = {
  reviewId: PropTypes.string.isRequired,
  onDeleted: PropTypes.func,
};

export default DeleteReviewButton;
