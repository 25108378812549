export const defaultPeers = [
  {
    _id: 'preview_self',
    firstName: 'Self',
    respondentId: 'self',
  },
  {
    _id: 'preview_peerb',
    firstName: 'Peer',
    lastName: 'B',
    respondentId: 'b',
  },
  {
    _id: 'preview_peerc',
    firstName: 'Peer',
    lastName: 'C',
    respondentId: 'c',
  },
  {
    _id: 'preview_peerd',
    firstName: 'Peer',
    lastName: 'D',
    respondentId: 'd',
  },
];
