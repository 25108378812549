import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getReport, getTemplateReport, updateReportResponses,
} from '../../../bridge';
import { logEvent } from '../../../helpers/logger';
import { useAuthContext } from '../../../hooks/useAuthContext';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';
import ReportBody from './ReportBody';
import ReportError from './ReportError';
import ReportNotReady from './ReportNotReady';

function Report() {
  const { user } = useAuthContext();
  const [result, setResult] = useState(null);
  const { templateId, respondentId } = useParams();
  const templateMode = !!templateId;
  const [loading, setLoading] = useState(true);
  const [reportError, setReportError] = useState(null);
  const [reportNotReady, setReportNotReady] = useState(null);

  useEffect(() => {
    if (!respondentId && !templateId) {
      return;
    }
    const fetchReport = async () => {
      let r = null;
      if (respondentId) {
        r = await getReport(respondentId, true);
      } else if (templateId) {
        r = await getTemplateReport(templateId);
      }
      await setLoading(false);
      if (r.error) {
        await setReportError(r.error);
        return;
      }
      if (r.response.reportNotReady) {
        setReportNotReady(r.response);
        return;
      }
      logEvent('Report', { respondentId, templateId });
      await setResult(r.response);
    };
    fetchReport();
  }, [respondentId, templateId]);

  const updateResponse = async (section, subSection, value) => {
    if (templateMode) {
      return;
    }
    const response = await updateReportResponses(respondentId, section, subSection, value);
    if (response.error) {
      await setReportError(response.error);
    }
  };
  if (!user && !templateMode && !(new URLSearchParams(document.location.search).get('token'))) {
    return <SignedOut />;
  }

  if (loading) {
    return <Loading />;
  }

  if (reportError) {
    return <ReportError error={reportError} />;
  }

  if (reportNotReady) {
    return <ReportNotReady report={reportNotReady} />;
  }

  if (!result) {
    return null;
  }

  return (
    <Page>
      <ContentSection>
        <ReportBody
          reportSections={result.reportSections}
          respondent={result.respondent}
          survey={result.survey}
          updateResponse={updateResponse}
          templateMode={templateMode}
        />
        <footer className="justify-content-center">
          &copy; Copyright 2023 itpmetrics.com
        </footer>
      </ContentSection>
    </Page>
  );
}

export default Report;
