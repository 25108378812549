import PropTypes from 'prop-types';
import React from 'react';

import Page from '../common/Page';
import LockScreen from '../verification/LockScreen';
import FooterStandard from './FooterStandard';
import Hero from './Hero';
import Processes from './Processes';
import Services from './Services';

function Landing({ login, logout, verify }) {
  return (
    <Page unfixed={false}>
      <Hero login={login} logout={logout} />
      <LockScreen show={verify} />
      <Processes />
      <Services />
      <FooterStandard />
    </Page>
  );
}

Landing.propTypes = {
  login: PropTypes.bool,
  logout: PropTypes.bool,
  verify: PropTypes.bool,
};

Landing.defaultProps = {
  login: false,
  logout: false,
  verify: false,
};

export default Landing;
