import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function SelectReleaseMode({ initialReleaseMode, onChange }) {
  const [releaseMode, setReleaseMode] = useState(initialReleaseMode);

  const changeReleaseMode = (value) => {
    setReleaseMode(value);
    onChange(value);
  };

  return (
    <>
      <p className="fw-bold">
        Please select when you'd like the survey reports to be released:
      </p>
      <Form.Group>
        <Form.Check
          value={releaseMode || ''}
          type="radio"
          label={(
            <div onClick={() => changeReleaseMode('default')} style={{ cursor: 'pointer' }}>
              <div>Wait for team completion</div>
              <div className="fw-light">Each team member needs to complete their survey. Reports will be released automatically.</div>
            </div>
              )}
          onChange={() => changeReleaseMode('default')}
          checked={releaseMode === 'default'}
          onClick={() => changeReleaseMode('default')}
        />
        <Form.Check
          value={releaseMode || ''}
          type="radio"
          label={(
            <div onClick={() => changeReleaseMode('review')} style={{ cursor: 'pointer' }}>
              <div>Only after I review and approve the feedback</div>
              <div className="fw-light">Each team member needs to complete their survey and the feedback needs to be reviewed and approved.</div>
            </div>
              )}
          onChange={() => setReleaseMode('review')}
          checked={releaseMode === 'review'}
          onClick={() => changeReleaseMode('review')}
        />
      </Form.Group>
    </>
  );
}

SelectReleaseMode.propTypes = {
  initialReleaseMode: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectReleaseMode;
