import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { DebouncedTextArea } from '../common/DebouncedTextArea';
import { ReportBarChart } from './ReportBarChart';

export function ReportBarChartSection({ respondent, updateResponse, disableReportResponse }) {
  const { reportResponse = {}, report = {} } = respondent;
  return (Object.keys(report.peerRating || [])
    .filter((trait) => (trait === 'Qualitative' ? report.peerRating[trait].length > 0 : true))
    .map(
      (trait) => (
        <Card key={`report-section-chart-team-player-${trait}`} className="pagebreak py-3 my-3">
          <Card.Body>
            {trait === 'Qualitative' ? (
              <>
                <div className="m-3 p-3">
                  <h2>Peer Feedback</h2>
                </div>
                <div className="m-3 p-3">
                  {report.peerRating[trait].map((feedback, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                    <div key={`report-section-chart-team-player-${trait}-${index}`}>
                      <i>{feedback}</i>
                      <hr />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="m-3 p-3">
                  <h2>{report.selfRating[trait].caption || trait}</h2>
                  <div className="pt-3">
                    {ReactHtmlParser(report.selfRating[trait].description)}
                  </div>
                </div>
                <ReportBarChart
                  peerRating={report.peerRating[trait].score}
                  selfRating={report.selfRating[trait].score}
                />
                <br />
                <div className="m-3 p-3">
                  <div>{ReactHtmlParser(report.peerRating[trait].scoreDescription)}</div>
                </div>
                {report.selfRating[trait].goal && (
                <div className="m-3 p-3">
                  <br className="print-only" />
                  <h4>Goal</h4>
                  <div>{ReactHtmlParser(report.selfRating[trait].goal)}</div>
                  {!disableReportResponse
                && (
                <DebouncedTextArea
                  originalValue={(reportResponse[trait] || {}).goal || ''}
                  onChanged={(key, _id, value) => updateResponse(trait, 'goal', value)}
                />
                )}
                </div>
                )}
              </>
            )}

          </Card.Body>
        </Card>
      ),
    ));
}

ReportBarChartSection.propTypes = {
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
  updateResponse: PropTypes.func,
  disableReportResponse: PropTypes.bool,
};
