import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ReportNotReady({ report }) {
  const navigate = useNavigate();
  return (
    <Modal
      show
      onHide={() => navigate('/dashboard')}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Report Not Ready</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {report.message}
        </p>
        {report.teamMemberCompleted && report.teamMemberTotal && (
          <p>
            {'Only '}
            <b>{report.teamMemberCompleted}</b>
            {' out of '}
            <b>{report.teamMemberTotal}</b>
            {' team members have completed the survey.'}
          </p>
        )}
        <p>
          Please select the "Back" button to return to the
          dashboard.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => navigate('/dashboard')}>Back</Button>
      </Modal.Footer>
    </Modal>
  );
}

ReportNotReady.propTypes = {
  report: PropTypes.shape({
    message: PropTypes.string,
    teamMemberCompleted: PropTypes.number,
    teamMemberTotal: PropTypes.number,
  }),
};

ReportNotReady.defaultProps = {
  report: { teamMemberCompleted: 0, teamMemberTotal: 0 },
};
