/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import conflictManagement from '../../../assets/itpmetrics/conflict-management-img.png';
import peer from '../../../assets/itpmetrics/peer-feedback-img.png';
import personality from '../../../assets/itpmetrics/personality-img.png';
import team from '../../../assets/itpmetrics/team-health-img.png';
import Section from '../../common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';

export const reviews = [
  [
    {
      title: 'Peer Feedback Review',
      type: 'peerFeedbackReview',
      description: 'To be used for students who took Peer Feedback assessments on the legacy www.itpmetrics.com site.',
      image: peer,
    },
  ],
];

export const services = [
  [
    {
      title: 'Team PLAYER',
      id: 'teamPlayer',
      description:
      "This assessment provides team members insights into their teamwork competencies and how to become a stronger team PLAYER. A personalized report provides feedback on team member's strongest and developing competencies, along with suggested improvement behaviors.",
      image: peer,
      type: 'peer',
    },
    {
      title: 'Team PLAYER Facets',
      id: 'teamPlayerFacets',
      description:
      "This extended assessment provides team members insights into their teamwork competencies and how to become a stronger team PLAYER. Assessment administrators will receive more detailed insights while team members receive a personalized report with feedback on team member's strongest and developing competencies, along with suggested improvement behaviors.",
      image: peer,
      type: 'peer',
    },
  ],
  [
    {
      title: 'Entrepreneurial Team Member Effectiveness',
      id: 'entEffectiveness',
      description:
      "This assessment is designed to help you become a stronger entrepreneurial team member. After the Peer Feedback assessment is completed by all team members, a report is generated. The report rank orders the team member's strongest to developing competencies, along with suggested potential improvement behaviors.",
      image: peer,
      type: 'peer',
    },
    {
      title: 'Peer Feedback',
      id: 'peerFeedback',
      description:
      "This assessment allows team members to gain insight regarding their teamwork competencies. After the Peer Feedback assessment is completed by all team members, a report is generated. The report rank orders the team member's strongest to developing competencies, along with suggested potential improvement behaviors.",
      image: peer,
      type: 'peer',
    },
  ],
  [
    {
      title: 'Team Dynamics',
      id: 'teamDynamics',
      description:
      'The Team CARE model was developed by ITP Lab as a prescription for healthy teamwork. This assessment will allow you to assess the health of your team. A report is also generated to provide you with an idea of how you can direct your future actions toward improving teamwork.',
      image: team,
      type: 'team',
    },
    {
      title: 'Peer Feedback and Team Dynamics',
      id: 'peerFeedbackTeamDynamics',
      description:
      "This assessment allows team members to gain insight regarding their teamwork competencies. After the Peer Feedback assessment is completed by all team members, a report is generated. The report rank orders the team member's strongest to developing competencies, along with suggested potential improvement behaviors.",
      image: team,
      type: 'peer',
    },
  ],
  [
    {
      title: 'Personality',
      id: 'personality',
      description:
      'This report outlines your level on five factors of personality based on your responses to the questionnaire you completed. It also describes how these personality traits can relate to your team interactions and experiences in teamwork.',
      image: personality,
      type: 'individual',
    },
    {
      title: 'Conflict Management',
      id: 'conflictManagement',
      description:
      'This assessment helps to build awareness and create discussion about personal styles of conflict management. This information can be used to gain greater awareness about scenarios in which each style would be the most effective.',
      image: conflictManagement,
      type: 'individual',
    },
  ],
];

function Services() {
  return (
    <Section bg="light" className="text-center">
      <SectionHeader
        title="Surveys"
        subtitle=""
      />
      {services.map((row, rowIndex) => (
        <Row className="mt-6 g-6" key={`service-${rowIndex}`}>
          {row.map((service, columnIndex) => (
            <Col lg={6} className="mt-6 mt-lg-0" key={`service-${rowIndex}-${columnIndex}`}>
              <CardService {...service} />
            </Col>
          ))}
        </Row>
      ))}
    </Section>
  );
}

export default Services;
