import React from 'react';

import ErrorDialog from './ErrorDialog';

function SurveyNotFound() {
  return (
    <ErrorDialog
      content={(
        <>
          <p className="lead text-800 font-sans-serif fw-semi-bold">
            The survey you're looking has been deleted.
            Please contact your survey administrator.
          </p>
          <hr />
          <p>
            If you think this is a mistake, please contact support@itpmetrics.com.
          </p>
        </>
      )}
    />
  );
}

export default SurveyNotFound;
