import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';

import conflictManagementImg from '../../../assets/itpmetrics/conflict-management-img.png';
import peerFeedbackImg from '../../../assets/itpmetrics/peer-feedback-img.png';
import personalityImg from '../../../assets/itpmetrics/personality-img.png';
import teamPlayerImg from '../../../assets/itpmetrics/team-health-img.png';
import { downloadLegacyReport, downloadReport, downloadTemplateReport } from '../../../bridge';
import { formatDate } from '../../../helpers/itpmetricsUtils';
import { ITPMETRICS_BLUE } from '../common/Colours';

export function ReportTitleSection({ respondent, templateMode }) {
  const template = {
    personality: {
      title: 'Personality Report',
      tagLine: 'Understanding Your Personality and Teamwork Experiences',
      imgSrc: personalityImg,
      imgAltText: 'personality-logo',
    },
    conflictManagement: {
      title: 'Conflict Management Styles',
      tagLine: 'Discover and Manage Your Conflict Style',
      imgSrc: conflictManagementImg,
      imgAltText: 'conflictManagement-logo',
    },
    teamPlayer: {
      title: 'Team Player Report',
      tagLine: 'Understanding Your Teamwork Experiences',
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    teamPlayerFacets: {
      title: 'Team Player Facets Report',
      tagLine: 'Understanding Your Teamwork Experiences',
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-facets-logo',
    },
    peerFeedback: {
      title: 'Peer Feedback Report',
      tagLine: 'Understanding Your Peer Experiences',
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackExpress: {
      title: 'Peer Feedback Express',
      tagLine: 'Understanding Your Peer Experiences',
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackMedian: {
      title: 'Peer Feedback - Self Rated, Peer Rated, and Group Median',
      tagLine: 'Understanding Your Peer Experiences',
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
    },
    teamDynamics: {
      title: 'Team Dynamics Report',
      tagLine: 'CARE MODEL - Diagnostics & Prescriptions for a Healthy Team',
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    peerFeedbackTeamDynamics: {
      title: 'Peer Feedback and Team Dynamics Report',
      tagLine: 'Understanding Your Peer Experiences',
      imgSrc: teamPlayerImg,
      imgAltText: 'team-player-logo',
    },
    entEffectiveness: {
      title: 'Entrepreneurial Team Member Effectiveness Report',
      tagLine: 'Creating better entrepreneurial teams',
      imgSrc: peerFeedbackImg,
      imgAltText: 'team-player-logo',
      hideInstitution: true,
    },
  };
  const [isLoading, setLoading] = useState(false);
  const report = template[respondent.templateId] || {
    title: '',
    tagLine: '',
    imgSrc: null,
    imgAltText: '',
  };
  const onDownload = async () => {
    setLoading(true);
    // eslint-disable-next-line no-nested-ternary
    const { response } = templateMode
      ? await downloadTemplateReport(respondent.templateId)
      : respondent.legacy
        ? await downloadLegacyReport(respondent.respondentId)
        : await downloadReport(respondent.respondentId);
    if (!response) {
      Swal.fire({
        title: 'Download Error',
        text: 'Error generating your report: If this persists, please contact support@itpmetrics.com.',
        icon: 'error',
        confirmButtonColor: ITPMETRICS_BLUE,
        confirmButtonText: 'OK',
      });
      setLoading(false);
      return;
    }
    const blob = new Blob([response], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${report.title} - ${respondent.firstName} ${respondent.lastName}.pdf`;
    link.click();
    setLoading(false);
  };

  return (
    <Card className="py-3 my-3">
      <Card.Body>
        <div className="m-3 p-3">
          <div className="d-flex">
            {report.imgSrc && (
              <img
                src={report.imgSrc}
                alt={report.imgAltText}
                width="100"
                height="100"
                className="mx-3"
              />
            )}
            <span className="mx-3 my-4 primary report-title">{report.title}</span>
            <span className="d-flex report-download">
              <Button
                className="no-print"
                variant="primary"
                disabled={isLoading}
                onClick={!isLoading ? onDownload : null}
                style={{ whiteSpace: 'nowrap' }}
              >
                <FontAwesomeIcon icon={faDownload} />
                {isLoading ? '  Generating...' : '  Download'}
              </Button>
            </span>
          </div>
          <br />
          <h4>{report.tagLine}</h4>
          <br />
          <h5>
            Report Prepared For:
            <br />
            {`${respondent.firstName} ${respondent.lastName}`}
            <br />
            {`${formatDate(respondent.completedAt)}`}
            <br />
          </h5>
          <br />
          <div>
            {!report.hideInstitution && (
            <>
              <br />
              Individual and Team Performance Lab
              <br />
              Department of Psychology
              <br />
              The Unviersity of Calgary
            </>
            )}

          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

ReportTitleSection.propTypes = {
  respondent: PropTypes.shape({
    respondentId: PropTypes.string,
    completedAt: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    templateId: PropTypes.string,
    legacy: PropTypes.bool,
  }),
  templateMode: PropTypes.bool,
};

ReportTitleSection.defaultProps = {
  respondent: null,
  templateMode: false,
};
