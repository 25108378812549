import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button, Card,
  Col, Form, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { getPrivacyPolicy, saveProfile } from '../../../bridge';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLogin } from '../../../hooks/useLogin';
import ContentSection from '../common/ContentSection';
import Loading from '../common/Loading';
import Page from '../common/Page';
import SignedOut from '../common/SignedOutDialog';
import DateSelection from './DateSelection';
import PrivacyPolicy from './PrivacyPolicy';

function Profile({ hasLabel }) {
  const { handleDispatch } = useLogin();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [policyId, setPolicyId] = useState('');
  const [inProgress, setInProgress] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    organization: '',
    policyId: '',
  });

  const loadPrivacy = async () => {
    const { response } = await getPrivacyPolicy();
    setPolicyId(response.policyId);
    setPrivacyPolicy(response.content);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const diff = {};
    Object.keys(formData).forEach((key) => {
      if (user[key]) {
        diff[key] = user[key];
      }
    });
    setFormData({ ...formData, ...diff });
  }, [user]);

  useEffect(() => {
    if (!privacyPolicy) {
      loadPrivacy();
    }
  }, [privacyPolicy]);

  // Handler
  const handleSubmit = async () => {
    setInProgress(true);
    const { response, error } = await saveProfile(formData);
    setInProgress(false);
    if (error) {
      toast.error(error, {
        theme: 'colored',
      });
    } else {
      handleDispatch(response.token);
      navigate('/dashboard');
    }
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const genders = [
    { id: '', title: 'Select gender' },
    { id: 'male', title: 'Male' },
    { id: 'female', title: 'Female' },
    { id: 'non-binary', title: 'Non-binary' },
    { id: 'prefer-not-to-say', title: 'Prefer not to say' },
    { id: 'prefer-to-self-describe', title: 'Prefer to self describe' },
  ];

  if (!user) {
    return <SignedOut />;
  }

  if (!privacyPolicy) {
    return <Loading />;
  }
  return (
    <Page>
      <ContentSection>
        <ToastContainer />
        <Card className="rounded" style={{ maxWidth: '700px', margin: '0 auto' }}>
          <Card.Body className="FormCard">
            <h4 className="my-2">Profile</h4>
            <Row>
              <Form.Group className="mb-3" as={Col} sm={6}>
                First Name
                {hasLabel && <Form.Label>First Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Enter First Name' : ''}
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={!formData.firstName}
                  autoFocus
                  aria-label="First Name"
                />
                {!formData.firstName && (
                  <Form.Control.Feedback type="invalid">
                    Please enter first name.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3" as={Col} sm={6}>
                Last Name
                {hasLabel && <Form.Label>Last Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Enter Last Name' : ''}
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleFieldChange}
                  type="text"
                  required
                  isInvalid={!formData.lastName}
                  aria-label="Last Name"
                />
                {!formData.lastNameName && (
                  <Form.Control.Feedback type="invalid">
                    Please enter lastname.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  Gender
                  {hasLabel && <Form.Label>Gender</Form.Label>}
                  <Form.Select
                    placeholder="Select your gender"
                    value={formData.gender}
                    name="gender"
                    onChange={handleFieldChange}
                    type="select"
                    aria-label="Gender"
                  >
                    {genders.map((gender) => (
                      <option key={gender.id} value={gender.id}>{gender.title}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  Organization
                  {hasLabel && <Form.Label>Organization</Form.Label>}
                  <Form.Control
                    placeholder={!hasLabel ? 'Enter your organization' : ''}
                    value={formData.organization}
                    name="organization"
                    onChange={handleFieldChange}
                    type="text"
                    aria-label="Organization"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group>
                Date of Birth
                {hasLabel && <Form.Label>Date of Birth</Form.Label>}
                <div>
                  <DateSelection setFormData={setFormData} formData={formData} user={user} />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <div>
                  {user?.privacyPolicy}
                  <PrivacyPolicy formData={formData} setFormData={setFormData} privacyPolicy={privacyPolicy} policyId={policyId} />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Col className="col-auto ms-auto">
                <Button
                  disabled={inProgress || !formData.firstName || !formData.lastName || !formData.policyId}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </ContentSection>
    </Page>
  );
}

Profile.propTypes = {
  hasLabel: PropTypes.bool,
};

export default Profile;
