import React from 'react';

import ErrorDialog from './ErrorDialog';

function UnexpectedError() {
  return (
    <ErrorDialog
      content={(
        <>
          <p className="fw-bold">
            An unexpected error has occured
          </p>
          <hr />
          <p>
            If this problem persists, please contact support@itpmetrics.com.
          </p>
        </>
      )}
    />
  );
}

export default UnexpectedError;
