import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { ReportConflictChart } from './ReportConflictChart';
import { ReportConflictConcernChart } from './ReportConflictConcernChart';
import { ReportConflictProcessChart } from './ReportConflictProcessChart';
import { ReportSpiderChart } from './ReportSpiderChart';

export function ReportCaptionSection({ section, respondent }) {
  const { report, templateId } = respondent;
  return (
    <Card className="pagebreak py-3 my-3">
      <Card.Body>
        <div className="m-3 p-3">
          {section.title && <h2>{section.title}</h2>}
          <br className="print-only" />
        </div>
        {section.sections.map((subSection) => (
          <div className="m-3 p-3" key={`report-subsection-caption-${subSection.key}`}>
            {subSection.pagebreak && <div className="pagebreak" />}
            {subSection.title && <h4>{subSection.title}</h4>}
            {subSection.key === 'reportSpiderChart' && <ReportSpiderChart report={report} type={templateId} />}
            {subSection.key === 'assessmentDevelopmentProcess' && <ReportConflictProcessChart subSection={subSection} />}
            {subSection.key === 'concernForOthersPlot' && <ReportConflictConcernChart report={report} />}
            {subSection.key === 'percentilePlot' && <ReportConflictChart report={report} title="PERCENTILE %" />}
            <div>{ReactHtmlParser(subSection.content)}</div>
            <br />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
}

ReportCaptionSection.propTypes = {
  section: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.string,
    })),
  }),
  respondent: PropTypes.shape({
    createdAt: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    response: PropTypes.object,
    report: PropTypes.object,
    reportResponse: PropTypes.object,
    status: PropTypes.string,
    surveyId: PropTypes.string,
    templateId: PropTypes.string,
    updatedAt: PropTypes.string,
    respondentId: PropTypes.string,
  }),
};

ReportCaptionSection.defaultProps = {
  section: null,
  respondent: {},
};
