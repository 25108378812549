import PropTypes from 'prop-types';
import React from 'react';
import { Card, Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { ReportConflictChart } from './ReportConflictChart';

export function ReportConflictChartSection({ respondent }) {
  const { report } = respondent;
  const pageOrder = [
    'Integrating',
    'Accommodating',
    'Compromising',
    'Avoiding',
    'Dominating',
  ];
  const pageOrderedEntries = pageOrder.map((bfiKey) => ({ bfiKey, bfiValue: report[bfiKey] }));

  return (pageOrderedEntries.map(({ bfiKey, bfiValue }) => (
    <Card key={`report-section-chart-${bfiKey}`} className="pagebreak py-3 my-3">
      <Card.Body>
        <div className="m-3 p-3">
          <h2>{bfiKey}</h2>
        </div>
        <div />
        <div className="m-3 p-3">
          <h5>{bfiValue.IdealUsesTitle}</h5>
          {ReactHtmlParser(bfiValue.IdealUsesContent)}
        </div>
        <div className="m-3 p-3">
          <h5>{bfiValue.ExamplesTitle}</h5>
          {ReactHtmlParser(bfiValue.ExamplesContent)}
        </div>
        <ReportConflictChart report={report} activeKey={bfiKey} />
        <Row>
          <div>
            <p className="ms-4 report-conflict-text-score">
              {`Your score on this style is: ${bfiValue.score}`}
            </p>
          </div>
          <div className="ms-4">
            {bfiValue.message}
          </div>
        </Row>
      </Card.Body>
    </Card>
  )));
}

ReportConflictChartSection.propTypes = {
  respondent: PropTypes.shape({
    report: PropTypes.shape({
      Dominating: PropTypes.shape({
        percentile: PropTypes.number,
        message: PropTypes.string,
        score: PropTypes.string,
        IdealUsesTitle: PropTypes.string,
        IdealUsesContent: PropTypes.string,
        ExamplesTitle: PropTypes.string,
        ExamplesContent: PropTypes.string,
      }),
      Avoiding: PropTypes.shape({
        percentile: PropTypes.number,
        message: PropTypes.string,
        score: PropTypes.string,
        IdealUsesTitle: PropTypes.string,
        IdealUsesContent: PropTypes.string,
        ExamplesTitle: PropTypes.string,
        ExamplesContent: PropTypes.string,
      }),
      Integrating: PropTypes.shape({
        percentile: PropTypes.number,
        message: PropTypes.string,
        score: PropTypes.string,
        IdealUsesTitle: PropTypes.string,
        IdealUsesContent: PropTypes.string,
        ExamplesTitle: PropTypes.string,
        ExamplesContent: PropTypes.string,
      }),
      Accommodating: PropTypes.shape({
        percentile: PropTypes.number,
        message: PropTypes.string,
        score: PropTypes.string,
        IdealUsesTitle: PropTypes.string,
        IdealUsesContent: PropTypes.string,
        ExamplesTitle: PropTypes.string,
        ExamplesContent: PropTypes.string,
      }),
      Compromising: PropTypes.shape({
        percentile: PropTypes.number,
        message: PropTypes.string,
        score: PropTypes.string,
        IdealUsesTitle: PropTypes.string,
        IdealUsesContent: PropTypes.string,
        ExamplesTitle: PropTypes.string,
        ExamplesContent: PropTypes.string,
      }),
    }),
  }),
};
